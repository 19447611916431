<template>
  <div>
    <div id="component">
      <div class="filter-panel">
        <input
            v-model="queryBuildingParams.name"
            class="cs-input"
            placeholder="搜索楼名"
            style="margin-left: 0; border: 1px solid #999; border-radius: 4px"
            type="text"
        />
        <CSSelect style="margin-right: 30px; min-width: 120px">
          <select v-model="queryBuildingParams.state">
            <option value="">楼栋状态不限</option>
            <option :value="1">启用</option>
            <option :value="2">禁用</option>
            <option :value="3">作废</option>
          </select>
        </CSSelect>
        <button
            class="btn btn-primary"
            style="width: 50px; height: 30px; padding: 0"
            type="button"
            v-on:click="_queryFloorMethod()"
        >
          查询
        </button>
      </div>
      <div class="result-panel">
        <CSTable :thead-top="filterHeight">
          <template v-slot:header>
            <div
                v-if="
                DEPARTMENT_TYPES.OPERATIONS === +accountInfo.dutyType ||
                DEPARTMENT_TYPES.DIRECTOR == +accountInfo.dutyType
              "
                class="table-header-panel text-right"
            >
              <button
                  class="btn btn-primary sticky-right btn-sm"
                  type="button"
                  v-on:click="_openAddFloorModal()"
              >
                <svg aria-hidden="true" class="icon">
                  <use xlink:href="#icon-menujiahao"></use>
                </svg>
                添加楼栋
              </button>
            </div>
          </template>
          <template v-slot:thead>
            <tr>
              <th>添加时间</th>
              <th>楼栋编号</th>
              <th>楼栋ID</th>
              <th>楼名</th>
              <th>楼栋状态</th>
              <th>楼层</th>
              <th>连通楼栋楼层</th>
              <th>创建人</th>
              <th>操作</th>
            </tr>
          </template>
          <template v-slot:tbody>
            <tr v-for="(building, index) in buildingList" :key="index">
              <td class="date-td">{{ building.createTime }}</td>
              <td>{{ building.buildingNumber ? building.buildingNumber : '-' }}</td>
              <td>{{ building.code || "-" }}</td>
              <td>{{ building.name }}</td>
              <td>{{ buildingState[building.state] || "-" }}</td>
              <td>
                <template v-if="building.aboveGroundFloor">
                  {{ building.aboveGroundFloor }}
                </template>
                <template v-if="building.undergroundFloor">
                  至-{{ building.undergroundFloor }}
                </template>
              </td>
              <td>
                <template v-if="building.relevanceBuilding">
                  <div v-for="item in building.relevanceBuilding.split(',')" :key="item">{{ item }}</div>
                </template>
                <template v-else>
                  -
                </template>

              </td>
              <td>{{ building.createUserName }}【{{ building.jobTitle }}】</td>
              <!-- <td class="text-center">
                                        {{ floor.userName }}
                                    </td> -->
              <td>
                <div v-if="building.state !== 3" class="btn-group">
                  <button
                      aria-expanded="false"
                      aria-haspopup="true"
                      class="btn btn-primary dropdown-toggle"
                      data-toggle="dropdown"
                      type="button"
                  >
                    操作
                  </button>

                  <ul class="dropdown-menu">
                    <li
                        v-if="building.state === 2"
                        style="width: 100%"
                        @click="changeBuildingState(building.id, 1)"
                    >
                      <a style="width: 100%">启用</a>
                    </li>
                    <li
                        v-if="building.state === 1"
                        @click="changeBuildingState(building.id, 2)"
                    >
                      <a style="width: 100%">禁用</a>
                    </li>
                    <li @click="changeBuildingState(building.id, 3)">
                      <a style="width: 100%">作废</a>
                    </li>
                    <li @click="updateBuildingState(building)">
                      <a style="width: 100%">修改楼栋</a>
                    </li>
                  </ul>
                </div>
                <template v-else>-</template>
              </td>
            </tr>
          </template>
        </CSTable>

        <!-- 分页 -->
        <Pagination componentName="Pagination" name="pagination"></Pagination>
      </div>

      <AddFloor componentName="AddFloor" name="addFloor"></AddFloor>


    </div>
  </div>
</template>
<script>
import Pagination from "@/components/Pagination.vue";
import AddFloor from "@/components/AddFloor.vue";
import CSSelect from "@/components/common/CSSelect";
import {DEPARTMENT_TYPES} from "@/constant";
import {changeBuildingStateUrl, queryBuildingUrl} from "@/requestUrl";
import CSTable from "@/components/common/CSTable";

export default {
  props: {},
  components: {
    CSTable,
    Pagination,
    AddFloor,
    CSSelect,
  },
  created() {
    window.addEventListener("keydown", this._queryFloorMethodDown);
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this._queryFloorMethodDown);
  },
  deactivated() {
    window.removeEventListener("keydown", this._queryFloorMethodDown);
  },
  data() {
    return {
      floorItem: {},//修改项信息
      filterHeight: 0,
      buildingState: {
        1: "启用",
        2: "禁用",
        3: "作废",
      },
      accountInfo: this.$vc.getCurrentStaffInfo(),
      queryBuildingParams: {
        id: "",
        code: "",
        regionCode: this.$vc.getCurrentRegion().code,
        name: "",
        state: "",
      },
      buildingList: [],
      DEPARTMENT_TYPES,
      pageParams: {
        pageNo: 1,
        pageSize: 10
      }
    };
  },

  mounted() {
    this.$vc.on(this.$route.path, "listFloor", "listFloorData", () => {
      this._queryFloorMethod();
    });
    this.$vc.on(
        this.$route.path,
        "pagination",
        "page_event",
        (_currentPage) => {
          this.pageParams.pageNo = _currentPage;
          this._queryFloorMethod(_currentPage);
        }
    );
    this._queryFloorMethod();
    this.filterHeight = document.querySelector(".filter-panel").offsetHeight;
    window.addEventListener("resize", () => {
      this.filterHeight = document.querySelector(".filter-panel").offsetHeight;
    });
  },
  methods: {
    //修改楼栋
    updateBuildingState(item) {
      this.floorItem = item;
      this.$vc.emit(this.$route.path, "addFloor", "openAddFloorModal", item);
    },
    _queryFloorMethodDown(e) {
      if (e.keyCode == 13) {
        this._queryFloorMethod();
      }
    },
    _openAddFloorModal: function () {
      //打开添加框
      this.$vc.emit(this.$route.path, "addFloor", "openAddFloorModal", false);
    },
    _queryFloorMethod: function (pageNo = this.pageParams.pageNo, pageSize = 10) {
      this.queryBuildingParams.regionCode = this.$vc.getCurrentRegion().code;
      this.$fly
          .post(queryBuildingUrl, {
            ...this.queryBuildingParams,
            pageNo,
            pageSize,
          })
          .then((res) => {
            if (pageNo === 1) {
              this.$vc.emit(this.$route.path, "pagination", "init", {
                total: res.data.total,
                currentPage: pageNo,
                pageSize: pageSize,
              });
            }
            this.buildingList = res.data.datas;
          });
    },
    _openAddUnit: function (_floor) {
      this.$vc.jumpToPage(
          "/flow/unitFlow?floorId=" +
          _floor.floorId +
          "&floorName=" +
          _floor.floorName
      );
    },
    /**
     * 修改楼栋状态接口
     * @param {Number} id 楼栋id
     * @param {Number} state 楼栋状态 1:启用 2:禁用 3:废除
     * */
    changeBuildingState(id, state) {
      this.$CSDialog.confirm({
        title: "提示",
        message: `确定${this.buildingState[state]}吗?`,
        onConfirm: () => {
          this.$fly
              .post(changeBuildingStateUrl, {
                id,
                state,
              })
              .then((res) => {
                if (res.code !== 0) {
                  this.$vc.message(res.msg);
                  return;
                }
                this.$vc.toast(`${this.buildingState[state]}成功`);
                this.$CSDialog.instance.closeDialog();
                this._queryFloorMethod();
              });
        },
      });
    },
  },
};
</script>
<style scoped>
input::-webkit-input-placeholder {
  color: #999;
}

input::-moz-placeholder {
  color: #999;
}

input::-moz-placeholder {
  color: #999;
}

input::-ms-input-placeholder {
  color: #999;
}
</style>
